import React from 'react'
import { FormattedMessage } from 'react-intl'
import { sendAppSignal } from 'hooks/app-signal'
import { nameToQuickGuideMap } from 'utilities/categories'
import { cls } from 'utilities/dom'
import { ModuleTitleLayout, Title, Subheading } from './s'
import { Simple } from 'components/button'
import Beta from 'components/beta'
import { HelpCircle } from 'styled-icons/feather'

const ModuleTitle = ({ which, title, quickGuide = true, sub = false, beta = false, className, ...props }) => {
    which = quickGuide ?
        nameToQuickGuideMap(which) :
        which

    const whichQuickGuide = (typeof quickGuide === 'string') ?
        quickGuide :
        which

    title = title ?? modules[which]?.title

    if(!title) {
        return null
    }

    className = cls([
        className,
        sub && 'sub',
        beta && 'beta'
    ])

    const Heading = sub ?
        Subheading :
        Title

    return (
        <ModuleTitleLayout
            {...props}
            {...(className ? { className } : null)}>
            <Heading>
                {(typeof title === 'string') && title}
                {(typeof title !== 'string') && <FormattedMessage {...title} />}
            </Heading>
            {!!quickGuide && (
                <Simple
                    {...(sub ? { size: 'medium' } : null)}
                    className="subtle"
                    onClick={() => sendAppSignal('quick-guide.show', whichQuickGuide)}
                    icon={HelpCircle} />
            )}
            {!!beta && <Beta />}
        </ModuleTitleLayout>
    )
}

const modules = {
    people: {
        title: {
            id: 'noun_people',
            defaultMessage: 'People'
        }
    },
    teams: {
        title: {
            id: 'noun_teams',
            defaultMessage: 'Teams'
        }
    },
    locations: {
        title: {
            id: 'noun_locations',
            defaultMessage: 'Locations'
        }
    },
    salary: {
        title: {
            id: 'noun_salary',
            defaultMessage: 'Salary'
        }
    },
    handbook: {
        title: {
            id: 'noun_handbook',
            defaultMessage: 'Handbook'
        }
    },
    absence: {
        title: {
            id: 'absence_heading',
            defaultMessage: 'Absence'
        }
    },
    meetings: {
        title: {
            id: 'noun_meetings',
            defaultMessage: 'Meetings'
        }
    },
    news: {
        title: {
            id: 'noun_news',
            defaultMessage: 'News'
        }
    },
    onboarding: {
        title: {
            id: 'noun_onboarding',
            defaultMessage: 'Onboarding'
        }
    },
    offboarding: {
        title: {
            id: 'noun_offboarding',
            defaultMessage: 'Offboarding'
        }
    },
    processes: {
        title: {
            id: 'noun_processes',
            defaultMessage: 'Processes'
        }
    },
    documents: {
        title: {
            id: 'documents_heading',
            defaultMessage: 'Documents'
        }
    },
    competence: {
        title: {
            id: 'noun_competence',
            defaultMessage: 'Competence'
        }
    },
    'competence-gap': {
        title: {
            id: 'noun_gap_analysis',
            defaultMessage: 'Gap analysis'
        }
    },
    equipment: {
        title: {
            id: 'noun_equipment',
            defaultMessage: 'Equipment'
        }
    },
    tasks: {
        title: {
            id: 'noun_tasks',
            defaultMessage: 'Tasks'
        }
    },
    deviation: {
        title: {
            id: 'noun_deviation_plural',
            defaultMessage: 'Deviations'
        }
    },
    insights: {
        title: {
            id: 'noun_insights',
            defaultMessage: 'Insights'
        }
    },
    surveys: {
        title: {
            id: 'noun_surveys',
            defaultMessage: 'Surveys'
        }
    },
    whistleblowing: {
        title: {
            id: 'noun_whistleblowing',
            defaultMessage: 'Whistleblowing'
        }
    },
    integrations: {
        title: {
            id: 'noun_integrations',
            defaultMessage: 'Integrations'
        }
    },
    'content-assistant': {
        title: {
            id: 'feature_content_assistant',
            defaultMessage: 'Content assistant'
        }
    }
}

export default ModuleTitle