import React, { forwardRef, Children } from 'react'
import styled from 'styled-components'
import { belowTablet } from 'utilities/styled'
import { flexColumnsCss, flexRowsCss, FlexChildShrink } from 'components/flex'
import { compact } from 'utilities/array'

const Actions = styled.div`
    @layer form {
        &:where(.vertical) {
            ${flexRowsCss}
            align-items: stretch;
            row-gap: 16px;
        }

        &:where(:not(.vertical)) {
            ${flexColumnsCss}
            flex-wrap: wrap;
            justify-content: end;
        }

        padding-block-start: 24px;

        &.compact,
        dialog & {
            padding-block-start: 0;
        }

        &.start {
            justify-content: start;
        }

        &.centered {
            justify-content: center;
        }

        &.end {
            justify-content: end;
        }

        &.spread {
            justify-content: space-between;
        }

        &.stretch {
            justify-content: stretch;
        }

        &:not(.vertical).reverse {
            flex-direction: row-reverse;
        }

        &.vertical.reverse {
            flex-direction: column-reverse;
        }

        &.align-center {
            align-items: center;
        }

        ${belowTablet`
            padding-block-start: 16px;
        `}
    }
`

const Action = styled(FlexChildShrink)`
    ${Actions}:where(.vertical) & > * {
        justify-content: center;
        width: 100%;
    }

    ${Actions}:where(:not(.vertical, .reverse)) &:not(:last-child) {
        margin-inline-end: 24px;
    }

    ${Actions}.stretch > &:only-child {
        flex: 1 0 100%;

        > * {
            justify-content: center;
            width: 100%;
        }
    }

    /* Right align */
    ${Actions}.spread > &:only-child {
        margin-inline-start: auto;
    }

    ${Actions}.spread:not(.reverse) > &:not(:only-child):first-child {
        margin-inline-end: auto;
    }

    ${Actions}.spread.reverse > &:not(:only-child):first-child {
        margin-inline-start: auto;
    }
`

export default forwardRef(({ children, salt, ...props }, ref) => {
    children = compact(Children.toArray(children))

    return (
        <Actions
            {...props}
            ref={ref}>
            {children.map((button, index) => (
                <Action key={`${salt}:action:${index}`}>
                    {button}
                </Action>
            ))}
        </Actions>
    )
})